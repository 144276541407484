import { GET_ALL_AVAILABLE_JOBS, GET_LAUNCHED_JOBS } from "../actions/types";

const initialState = {
  availables: [],
  launched: [],
};

export function jobsReducer(state = initialState, action) {
  let results = [];
  switch (action.type) {
    case GET_LAUNCHED_JOBS:
      return Object.assign({}, state, {
        ...state,
        launched: action.payload,
      });
    case GET_ALL_AVAILABLE_JOBS:
      action.payload.forEach((element) => {
        results.push({
          nom: element.nom,
          jobdefinition: element._id,
        });
      });
      return Object.assign({}, state, {
        ...state,
        availables: results,
      });
    default:
      return state;
  }
}
