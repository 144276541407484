import {
  GET_ALL_ORGANIZATIONS,
  ADD_ORGANIZATION,
  MODIFY_ORGANIZATION,
  REMOVE_ORGANIZATION,
  ADD_USER_TO_ORGANIZATION,
  MODIFY_USER_OF_ORGANIZATION,
  REMOVE_USER_OF_ORGANIZATION,
  ADD_SETTING_TO_ORGANIZATION,
  MODIFY_SETTING_OF_ORGANIZATION,
  REMOVE_SETTING_OF_ORGANIZATION,
  ADD_JOB_TO_ORGANIZATION,
  MODIFY_JOB_OF_ORGANIZATION,
  REMOVE_JOB_OF_ORGANIZATION,
} from "./types";
import {
  organisationService,
  userService,
  settingsService,
  jobDefinitionService,
} from "../../services";

import { alertActions } from "./alert.actions";

// ACTION CREATORS
export function addOrganization(organization) {
  return (dispatch) => {
    organisationService.add(organization).then(
      (orga) => {
        dispatch(addorganization(orga));
        dispatch(
          alertActions.success("L'organisation " + orga.nom + " a été crée")
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function addorganization(data) {
  return {
    type: ADD_ORGANIZATION,
    payload: data,
  };
}

export function modifyOrganization(index, data) {
  return (dispatch) => {
    organisationService.update(data).then(
      () => {
        dispatch(modifyorganization(index, data));
        dispatch(
          alertActions.success("L'organisation " + data.nom + " a été modifiée")
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function modifyorganization(index, data) {
  return {
    type: MODIFY_ORGANIZATION,
    payload: { index: index, data: data },
  };
}

export function removeOrganization(id) {
  return (dispatch) => {
    organisationService.remove(id).then(
      (organization) => {
        dispatch(removeorganization(organization));
        dispatch(
          alertActions.success(
            "L'organisation " + organization.nom + " a été supprimée"
          )
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function removeorganization(data) {
  return {
    type: REMOVE_ORGANIZATION,
    payload: data,
  };
}

export const getAllOrganizations = () => {
  return (dispatch) => {
    organisationService.getAll().then(
      (organisations) => {
        dispatch(getallOrganizations(organisations));
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
};

export function getallOrganizations(data) {
  return {
    type: GET_ALL_ORGANIZATIONS,
    payload: data,
  };
}

export function addUserToOrganization(index, newuser) {
  return (dispatch) => {
    userService.add(newuser).then(
      (user) => {
        dispatch(adduserToOrganization(index, user));
        dispatch(
          alertActions.success(
            "L'utilisateur " +
              newuser.prenom +
              " " +
              newuser.nom +
              " a été crée"
          )
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function adduserToOrganization(index, data) {
  return {
    type: ADD_USER_TO_ORGANIZATION,
    payload: { index: index, data: data },
  };
}

export function modifyUserOfOrganization(organizationIndex, userIndex, data) {
  return (dispatch) => {
    userService.update(data).then(
      (user) => {
        dispatch(modifyuserOfOrganization(organizationIndex, userIndex, user));
        dispatch(
          alertActions.success(
            "L'utilisateur " + user.prenom + " " + user.nom + " a été modifiée"
          )
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function modifyuserOfOrganization(organizationIndex, userIndex, data) {
  return {
    type: MODIFY_USER_OF_ORGANIZATION,
    payload: {
      organizationIndex: organizationIndex,
      userIndex: userIndex,
      data: data,
    },
  };
}

export function removeUserOfOrganization(index, id) {
  return (dispatch) => {
    userService.remove(id).then(
      () => {
        dispatch(removeuserOfOrganization(index, id));
        dispatch(alertActions.success("L'utilisateur a été supprimé"));
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function removeuserOfOrganization(index, data) {
  return {
    type: REMOVE_USER_OF_ORGANIZATION,
    payload: { index: index, data: data },
  };
}

/** SETTINGS MANAGEMENT  */
export function addSettingToOrganization(index, data) {
  return (dispatch) => {
    settingsService.add(data).then(
      (setting) => {
        dispatch(addsettingToOrganization(index, setting));
        dispatch(alertActions.success("Le paramètre de connexion a été créé"));
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function addsettingToOrganization(index, data) {
  return {
    type: ADD_SETTING_TO_ORGANIZATION,
    payload: { index: index, data: data },
  };
}

export function modifySettingOfOrganization(organizationIndex, jobIndex, data) {
  return (dispatch) => {
    settingsService.update(data).then(
      (setting) => {
        dispatch(
          modifysettingOfOrganization(organizationIndex, jobIndex, setting)
        );
        dispatch(
          alertActions.success(
            "Le paramètre de connexion " + setting.nom + " a été modifié"
          )
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function modifysettingOfOrganization(
  organizationIndex,
  settingIndex,
  data
) {
  return {
    type: MODIFY_SETTING_OF_ORGANIZATION,
    payload: {
      organizationIndex: organizationIndex,
      settingIndex: settingIndex,
      data: data,
    },
  };
}

export function removeSettingOfOrganization(index, id) {
  return (dispatch) => {
    settingsService.remove(id).then(
      () => {
        dispatch(removesettingOfOrganization(index, id));
        dispatch(
          alertActions.success("Le paramètre de connexion a été supprimé")
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function removesettingOfOrganization(index, data) {
  return {
    type: REMOVE_SETTING_OF_ORGANIZATION,
    payload: { index: index, data: data },
  };
}

/** JOBS FUNCTION  */
export function addJobToOrganization(index, data) {
  return (dispatch) => {
    jobDefinitionService.add(data).then(
      (jobdefinition) => {
        dispatch(addjobToOrganization(index, jobdefinition));
        dispatch(
          alertActions.success("Le job " + jobdefinition.nom + " a été créé.")
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function addjobToOrganization(index, data) {
  return {
    type: ADD_JOB_TO_ORGANIZATION,
    payload: { index: index, data: data },
  };
}

export function modifyJobOfOrganization(organizationIndex, jobIndex, data) {
  return (dispatch) => {
    jobDefinitionService.update(data).then(
      (jobdefinition) => {
        dispatch(
          modifyjobOfOrganization(organizationIndex, jobIndex, jobdefinition)
        );
        dispatch(
          alertActions.success(
            "Le job " + jobdefinition.nom + " a été modifié."
          )
        );
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function modifyjobOfOrganization(organizationIndex, jobIndex, data) {
  return {
    type: MODIFY_JOB_OF_ORGANIZATION,
    payload: {
      organizationIndex: organizationIndex,
      jobIndex: jobIndex,
      data: data,
    },
  };
}

export function removeJobOfOrganization(index, id) {
  return (dispatch) => {
    jobDefinitionService.remove(id).then(
      () => {
        dispatch(removejobOfOrganization(index, id));
        dispatch(alertActions.success("Le job a été supprimé."));
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function removejobOfOrganization(index, data) {
  return {
    type: REMOVE_JOB_OF_ORGANIZATION,
    payload: { index: index, data: data },
  };
}
