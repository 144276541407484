import React from "react";
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { CLink } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";

import { removeUserOfOrganization } from "src/redux/actions/organizationsActions";
import { UsersModal } from ".";
import { logOut } from "../redux/actions/usersActions";

const UsersReactGrid = (props) => {
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = React.useState();

  const [usersOpen, setUsersOpen] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState();
  const [modalBtnTxt, setModalBtnTxt] = React.useState();
  const [action, setAction] = React.useState();

  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  React.useEffect(() => {
    if (gridApi !== null) {
      const handleResize = () => {
        gridApi.sizeColumnsToFit();
      };
      window.addEventListener("resize", handleResize);
    }
  }, [gridApi]);

  React.useEffect(() => {
    if (gridColumnApi !== null) {
      const savedState = gridColumnApi.getColumnState();
      gridColumnApi.applyColumnState({ state: savedState });
    }
  }, [gridColumnApi]);

  React.useEffect(() => {
    if (gridApi !== null && props.entered) {
      gridApi.sizeColumnsToFit();
    }
  }, [props.show, props.entered]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let rowData = [];
    props.users.map((user) => {
      rowData.push({
        _id: user._id,
        Nom: user.nom,
        Prénom: user.prenom,
        Email: user.email,
        Profil: user.profil,
      });
    });

    setRowData(rowData);
  }, [props.organizations]); // eslint-disable-line react-hooks/exhaustive-deps

  const openUserModifyModal = (index) => {
    setAction("modify");
    setModalTitle("Modifier un utilisateur");
    setModalBtnTxt("Modifier");
    setSelectedUserIndex(index);
    setUsersOpen(true);
  };

  const deleteuser = (_id) => {
    confirmAlert({
      title: "Confirmation de suppression ",
      message:
        _id === loggedUser._id
          ? "Êtes-vous sûrs de vouloir supprimer définitivement votre compte ? Cette action ne peut pas être annulée."
          : "Vous allez supprimer cet utilisateur. Cette action ne peut pas être annulée",
      buttons: [
        {
          label: "OUI",
          onClick: () => {
            props.dispatch(removeUserOfOrganization(props.selectedIndex, _id));
            if (_id === loggedUser._id) props.dispatch(logOut());
          },
        },
        {
          label: "NON",
          onClick: () => {},
        },
      ],
    });
  };

  const setUsersOpenFromChild = (open) => {
    setUsersOpen(open);
  };

  const modifyUserButton = (agGridProps) => {
    return (
      <div>
        <CLink
          className="card-header-action"
          onClick={() => openUserModifyModal(agGridProps.rowIndex)}>
          <CIcon name={"cil-pencil"} />
        </CLink>
        <CLink
          className="card-header-action"
          onClick={() => deleteuser(agGridProps.data._id)}>
          <CIcon name={"cil-x-circle"} />
        </CLink>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        onGridReady={onGridReady}
        rowData={rowData}
        frameworkComponents={{
          modifyUserButton: modifyUserButton,
        }}>
        <AgGridColumn field="Nom" resizable></AgGridColumn>
        <AgGridColumn field="Prénom" resizable></AgGridColumn>
        <AgGridColumn field="Email" resizable></AgGridColumn>
        <AgGridColumn field="Profil" resizable></AgGridColumn>
        <AgGridColumn
          field="Actions"
          cellRenderer="modifyUserButton"
          resizable
        />
      </AgGridReact>
      <UsersModal
        open={usersOpen}
        setOpen={setUsersOpenFromChild}
        action={action}
        title={modalTitle}
        btnTxt={modalBtnTxt}
        selectedIndex={props.selectedIndex}
        selectedUserIndex={selectedUserIndex}></UsersModal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizations: state.organizations.data,
    jobs: state.jobs.data,
  };
}

UsersReactGrid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  entered: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(UsersReactGrid);
