import {
  GET_ALL_ORGANIZATIONS,
  ADD_ORGANIZATION,
  MODIFY_ORGANIZATION,
  REMOVE_ORGANIZATION,
  ADD_USER_TO_ORGANIZATION,
  MODIFY_USER_OF_ORGANIZATION,
  REMOVE_USER_OF_ORGANIZATION,
  ADD_SETTING_TO_ORGANIZATION,
  MODIFY_SETTING_OF_ORGANIZATION,
  REMOVE_SETTING_OF_ORGANIZATION,
  ADD_JOB_TO_ORGANIZATION,
  MODIFY_JOB_OF_ORGANIZATION,
  REMOVE_JOB_OF_ORGANIZATION,
} from "../actions/types";

const initialState = {
  data: [],
};

export function organizationsReducer(state = initialState, action) {
  let organizations;

  let newData = [...state.data];
  let updatedUsers;
  let updatedSettings;
  let updatedJobs;
  switch (action.type) {
    case GET_ALL_ORGANIZATIONS:
      return Object.assign({}, state, {
        ...state,
        data: action.payload,
      });
    case ADD_ORGANIZATION:
      return Object.assign({}, state, {
        ...state,
        data: [...state.data, action.payload],
      });
    case MODIFY_ORGANIZATION:
      organizations = [...state.data];
      organizations[action.payload.index] = action.payload.data;
      return Object.assign({}, state, {
        ...state,
        data: organizations,
      });
    case REMOVE_ORGANIZATION:
      organizations = [...state.data];
      organizations = organizations.filter(
        (el) => el._id !== action.payload._id
      );
      return Object.assign({}, state, {
        ...state,
        data: organizations,
      });
    case ADD_USER_TO_ORGANIZATION:
      updatedUsers = newData[action.payload.index].users;
      updatedUsers.push(action.payload.data);
      newData[action.payload.index]["users"] = updatedUsers;
      return Object.assign({}, state, {
        ...state,
        data: newData,
      });
    case MODIFY_USER_OF_ORGANIZATION:
      organizations = [...state.data];
      organizations[action.payload.organizationIndex].users[
        action.payload.userIndex
      ].nom = action.payload.data.nom;
      organizations[action.payload.organizationIndex].users[
        action.payload.userIndex
      ].prenom = action.payload.data.prenom;
      organizations[action.payload.organizationIndex].users[
        action.payload.userIndex
      ].email = action.payload.data.email;
      organizations[action.payload.organizationIndex].users[
        action.payload.userIndex
      ].profil = action.payload.data.profil;
      return Object.assign({}, state, {
        ...state,
        data: organizations,
      });
    case REMOVE_USER_OF_ORGANIZATION:
      updatedUsers = newData[action.payload.index].users;
      updatedUsers = updatedUsers.filter(
        (el) => el._id !== action.payload.data
      );
      newData[action.payload.index]["users"] = updatedUsers;
      return Object.assign({}, state, {
        ...state,
        data: newData,
      });
    case ADD_SETTING_TO_ORGANIZATION:
      updatedSettings = newData[action.payload.index].settings;
      updatedSettings.push(action.payload.data);
      newData[action.payload.index]["settings"] = updatedSettings;
      return Object.assign({}, state, {
        ...state,
        data: newData,
      });
    case MODIFY_SETTING_OF_ORGANIZATION:
      organizations = [...state.data];
      organizations[action.payload.organizationIndex].settings[
        action.payload.settingIndex
      ].nom = action.payload.data.nom;
      organizations[action.payload.organizationIndex].settings[
        action.payload.settingIndex
      ].url_pim = action.payload.data.url_pim;
      organizations[action.payload.organizationIndex].settings[
        action.payload.settingIndex
      ].token_pim = action.payload.data.token_pim;
      organizations[action.payload.organizationIndex].settings[
        action.payload.settingIndex
      ].host = action.payload.data.host;
      organizations[action.payload.organizationIndex].settings[
        action.payload.settingIndex
      ].login = action.payload.data.login;
      organizations[action.payload.organizationIndex].settings[
        action.payload.settingIndex
      ].password = action.payload.data.password;
      return Object.assign({}, state, {
        ...state,
        data: organizations,
      });
    case REMOVE_SETTING_OF_ORGANIZATION:
      updatedSettings = newData[action.payload.index].settings;
      updatedSettings = updatedSettings.filter(
        (el) => el._id !== action.payload.data
      );
      newData[action.payload.index]["settings"] = updatedSettings;
      return Object.assign({}, state, {
        ...state,
        data: newData,
      });
    case ADD_JOB_TO_ORGANIZATION:
      updatedJobs = newData[action.payload.index].jobs;
      updatedJobs.push(action.payload.data);
      newData[action.payload.index]["jobs"] = updatedJobs;
      return Object.assign({}, state, {
        ...state,
        data: newData,
      });
    case MODIFY_JOB_OF_ORGANIZATION:
      organizations = [...state.data];
      organizations[action.payload.organizationIndex].jobs[
        action.payload.jobIndex
      ].nom = action.payload.data.nom;
      organizations[action.payload.organizationIndex].jobs[
        action.payload.jobIndex
      ].setting = action.payload.data.setting;
      organizations[action.payload.organizationIndex].jobs[
        action.payload.jobIndex
      ].json = action.payload.data.json;
      return Object.assign({}, state, {
        ...state,
        data: organizations,
      });
    case REMOVE_JOB_OF_ORGANIZATION:
      updatedJobs = newData[action.payload.index].jobs;
      updatedJobs = updatedJobs.filter((el) => el._id !== action.payload.data);
      newData[action.payload.index]["jobs"] = updatedJobs;
      return Object.assign({}, state, {
        ...state,
        data: newData,
      });
    default:
      return state;
  }
}
