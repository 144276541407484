export const GET_ALL_ORGANIZATIONS = "GET_ALL_ORGANIZATIONS";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const MODIFY_ORGANIZATION = "MODIFY_ORGANIZATION";
export const REMOVE_ORGANIZATION = "REMOVE_ORGANIZATION";
export const ADD_USER_TO_ORGANIZATION = "ADD_USER_TO_ORGANIZATION";
export const MODIFY_USER_OF_ORGANIZATION = "MODIFY_USER_OF_ORGANIZATION";
export const REMOVE_USER_OF_ORGANIZATION = "REMOVE_USER_OF_ORGANIZATION";
export const ADD_SETTING_TO_ORGANIZATION = "ADD_SETTING_TO_ORGANIZATION";
export const MODIFY_SETTING_OF_ORGANIZATION = "MODIFY_SETTING_OF_ORGANIZATION";
export const REMOVE_SETTING_OF_ORGANIZATION = "REMOVE_SETTING_OF_ORGANIZATION";
export const ADD_JOB_TO_ORGANIZATION = "ADD_JOB_TO_ORGANIZATION";
export const MODIFY_JOB_OF_ORGANIZATION = "MODIFY_JOB_OF_ORGANIZATION";
export const REMOVE_JOB_OF_ORGANIZATION = "REMOVE_JOB_OF_ORGANIZATION";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const GET_ALL_AVAILABLE_JOBS = "GET_ALL_AVAILABLE_JOBS";
export const JOB_LAUNCH = "JOB_LAUNCH";
export const ACTIVATE_SCHEDULER = "ACTIVATE_SCHEDULER";
export const DEACTIVATE_SCHEDULER = "DEACTIVATE_SCHEDULER";
export const GET_LAUNCHED_JOBS = "GET_LAUNCHED_JOBS";
export const DELETEJOB = "DELETEJOB";

export const CHANGE_STATE = "CHANGE_STATE";
