import { combineReducers } from "redux";
import { organizationsReducer } from "./organizationsReducer";
import { jobsReducer } from "./jobsReducer";
import { usersReducer } from "./usersReducer";
import { changeState } from "./responsiveReducer";

const rootReducer = combineReducers({
  users: usersReducer,
  organizations: organizationsReducer,
  jobs: jobsReducer,
  responsive: changeState,
});

export default rootReducer;
