import React from "react";
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { CLink } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";

import { removeJobOfOrganization } from "src/redux/actions/organizationsActions";
import { JobsModal } from ".";
import { deactivateScheduler } from "../redux/actions/jobsActions";

const JobsReactGrid = (props) => {
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [selectedJobIndex, setSelectedJobIndex] = React.useState();

  const [jobsOpen, setJobsOpen] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState();
  const [modalBtnTxt, setModalBtnTxt] = React.useState();
  const [action, setAction] = React.useState();

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  React.useEffect(() => {
    if (gridColumnApi !== null) {
      const savedState = gridColumnApi.getColumnState();
      gridColumnApi.applyColumnState({ state: savedState });
    }
  }, [gridColumnApi]);

  React.useEffect(() => {
    if (gridApi !== null) {
      const handleResize = () => {
        gridApi.sizeColumnsToFit();
      };
      window.addEventListener("resize", handleResize);
    }
  }, [gridApi]);

  React.useEffect(() => {
    if (gridApi !== null && props.entered) {
      gridApi.sizeColumnsToFit();
    }
  }, [props.show, props.entered]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let rowData = [];
    props.jobs.map((job) => {
      let profilConnexionAssocie = props.organizations[
        props.selectedIndex
      ].settings.filter((setting) => job.setting === setting._id)[0];
      if (profilConnexionAssocie !== undefined) {
        rowData.push({
          _id: job._id,
          Nom: job.nom.split("- ")[1],
          "Paramètre de Connexion":
            "nom" in profilConnexionAssocie
              ? profilConnexionAssocie.nom.split("- ")[1]
              : "",
        });
      }
    });
    setRowData(rowData);
  }, [props.organizations]); // eslint-disable-line react-hooks/exhaustive-deps

  const openJobModifyModal = (index) => {
    setAction("modify");
    setModalTitle("Modifier un job");
    setModalBtnTxt("Modifier");
    setSelectedJobIndex(index);
    setJobsOpen(true);
  };

  const deletejob = (_id, nom) => {
    confirmAlert({
      title: "Confirmation de suppression ",
      message:
        "Vous allez supprimer ce job. Cette action ne peut pas être annulée",
      buttons: [
        {
          label: "OUI",
          onClick: () => {
            let jobToDelete = props.jobs.find((job => job._id === _id))
            const index = props.launched.map((lj) => lj.status === "active" ? lj.nom + " active" : lj.nom).indexOf(jobToDelete.nom + " active");
            if (index >= 0 && props.launched[index].status === "active") {
              props.dispatch(
                deactivateScheduler(
                  props.launched[index],
                  props.organizations,
                  true
                )
              );
            }
            props.dispatch(removeJobOfOrganization(props.selectedIndex, _id));
          },
        },
        {
          label: "NON",
          onClick: () => {},
        },
      ],
    });
  };

  const setJobsOpenFromChild = (open) => {
    setJobsOpen(open);
  };

  const downloadJSON = (tableprops) => {
    if (
      tableprops.rowIndex < props.organizations[props.selectedIndex].jobs.length
    ) {
      var data =
        "text/json;charset=utf-8," +
        encodeURIComponent(
          JSON.stringify(
            props.organizations[props.selectedIndex].jobs[tableprops.rowIndex].jsoncontent,
            null,
            2
          )
        );
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CLink
            style={{ marginRight: 2 }}
            className="card-header-action"
            href={"data: " + data}
            download={
              props.organizations[props.selectedIndex].jobs[
                tableprops.rowIndex
              ].nom.replace(" ", "-") +
              "_" +
              props.organizations[props.selectedIndex].nom.replace(" ", "-") +
              "_config.json"
            }>
            <CIcon name={"cil-cloud-download"} />
          </CLink>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const modifyJobButton = (agGridProps) => {
    return (
      <div>
        <CLink
          className="card-header-action"
          onClick={() => openJobModifyModal(agGridProps.rowIndex)}>
          <CIcon name={"cil-pencil"} />
        </CLink>
        <CLink
          className="card-header-action"
          onClick={() => deletejob(agGridProps.data._id, agGridProps.data.Nom)}>
          <CIcon name={"cil-x-circle"} />
        </CLink>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        onGridReady={onGridReady}
        rowData={rowData}
        frameworkComponents={{
          modifyJobButton: modifyJobButton,
          downloadJSON: downloadJSON,
        }}>
        <AgGridColumn field="Nom" resizable></AgGridColumn>
        <AgGridColumn field="Paramètre de Connexion" resizable></AgGridColumn>
        <AgGridColumn field="JSON" cellRenderer="downloadJSON" resizable />
        <AgGridColumn
          field="Actions"
          cellRenderer="modifyJobButton"
          resizable
        />
      </AgGridReact>
      <JobsModal
        open={jobsOpen}
        setOpen={setJobsOpenFromChild}
        action={action}
        title={modalTitle}
        btnTxt={modalBtnTxt}
        selectedIndex={props.selectedIndex}
        selectedJobIndex={selectedJobIndex}></JobsModal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizations: state.organizations.data,
    launched: state.jobs.launched,
  };
}

JobsReactGrid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  launched: PropTypes.array.isRequired,
  entered: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  jobs: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(JobsReactGrid);
