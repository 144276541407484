import React from "react";
import PropTypes from "prop-types";
import {
  CButton,
  CCol,
  CModal,
  CModalTitle,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
} from "@coreui/react";
import { connect } from "react-redux";

import {
  addOrganization,
  modifyOrganization,
} from "src/redux/actions/organizationsActions";

const OrganizationsModal = (props) => {
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    if (props.action !== undefined) {
      if (props.action === "add") {
        setName("");
      } else {
        setName(props.organizations[props.selectedIndex].nom);
      }
    }
  }, [props.selectedIndex, props.open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CModal
      show={props.open}
      onClose={() => props.setOpen(!props.open)}
      size="lg">
      <CModalHeader closeButton>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm
          action=""
          method="post"
          encType="multipart/form-data"
          className="form-horizontal">
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Nom
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row style={{ display: "none" }}>
            <CCol md="3">
              <CLabel></CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={name.length === 0}
          color="primary"
          onClick={() => {
            props.setOpen(!props.open);
            props.dispatch(
              props.action === "add"
                ? addOrganization({
                    nom: name,
                  })
                : modifyOrganization(props.selectedIndex, {
                    ...props.organizations[props.selectedIndex],
                    nom: name,
                  })
            );
          }}>
          {props.btnTxt}
        </CButton>{" "}
        <CButton color="secondary" onClick={() => props.setOpen(!props.open)}>
          Annuler
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

function mapStateToProps(state) {
  return {
    organizations: state.organizations.data,
  };
}

OrganizationsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  btnTxt: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(OrganizationsModal);
