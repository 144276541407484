export const userService = {
  getAll,
  getOnePasswordResetToken,
  add,
  update,
  remove,
  login,
  logout,
  askForResetToken,
  resetPassword
};
/**
 *
 * @returns
 */
function getAll() {
  const requestOptions = {
    method: "get",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/organisations",
    requestOptions
  ).then(handleResponse);
}
/**
 *
 * @returns
 */
 function getOnePasswordResetToken(passwordResetToken) {
  const requestOptions = {
    method: "get",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    process.env.REACT_APP_API_URL + `/v1/auth/passwordResetToken/${passwordResetToken}`,
    requestOptions
  ).then(handleResponse)
}
/**
 *
 * @param {*} user
 * @returns
 */
function add(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/users",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} user
 * @returns
 */
function update(user) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/users",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} user
 * @returns
 */
function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    process.env.REACT_APP_API_URL + `/v1/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} email
 * @param {*} password
 * @returns
 */
function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(process.env.REACT_APP_API_URL + "/v1/auth/login", requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user.user));
      return user;
    });
}
/**
 * 
 * @param {*} email
 * @returns
 */

function askForResetToken(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': "application/json" },
    body: JSON.stringify({ email })
  }

  return fetch(process.env.REACT_APP_API_URL + "/v1/auth/askForResetToken", requestOptions)
}

/**
 * 
 * @param {*} token
 * @param {*} newPassword
 * @returns
 */
function resetPassword(token, newPassword) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': "application/json" },
    body: JSON.stringify({ token, newPassword })
  }
  return fetch(process.env.REACT_APP_API_URL + "/v1/auth/resetPassword", requestOptions)
}

/**
 *
 */
function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}
/**
 *
 * @param {*} response
 * @returns
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text;
    if (!response.ok) {
      if (response.status === 401) {
        const error = "Connexion impossible. Vérifier vos identifiants ";
        return Promise.reject(error);
      }

      const error =
        (data &&
          JSON.parse(data).message &&
          JSON.parse(data).message.includes("email: ") &&
          JSON.parse(data).message.split("email: ")[1]) ||
        (data && JSON.parse(data).message) ||
        response.statusText;
      return Promise.reject(error);
    }
    return JSON.parse(data);
  });
}
