import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { PrivateRoute } from "./components";
import { messaging } from "./notification/firebaseinit";
import { alertActions } from "./redux/actions/alert.actions";
import PropTypes from "prop-types";
import {
  getAllAvailableJobs,
  getLaunchedJobs,
} from "./redux/actions/jobsActions";
import { getAllOrganizations } from "./redux/actions/organizationsActions";
import { connect } from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));
const Login = React.lazy(() => import("./views/pages/Login"));
const ForgetPassword = React.lazy(() => import("./views/recovery/ForgetPassword"))
const NewPassword = React.lazy(() => import("./views/recovery/ResetPassword"))


// Pages
/* requestFirebaseNotificationPermission()
  .then((firebaseToken) => {
    // eslint-disable-next-line no-console
    console.log(firebaseToken);
  })
  .catch((err) => {
    console.log(err)
    return err;
  }); */
messaging
  .requestPermission()
  .then(async function () {
    const token = await messaging.getToken();
    localStorage.setItem("msgtoken", token);
    console.log("token=", token);
  })
  .catch(function (err) {
    console.log("Unable to get permission to notify.", err);
  });
navigator.serviceWorker.addEventListener("message", (message) => {
  console.log(message.data["firebase-messaging-msg-data"]);
  if (message.data["firebase-messaging-msg-data"].data.type == "0") {
    alertActions.error(
      message.data["firebase-messaging-msg-data"].notification.body
    );
  } else {
    alertActions.success(
      message.data["firebase-messaging-msg-data"].notification.body
    );
  }
});

class App extends Component {
  componentDidMount() {
    this.props.dispatch(getAllOrganizations());
    this.props.dispatch(getAllAvailableJobs());
    this.props.dispatch(getLaunchedJobs());
  }

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/recovery"
              name="Recovery Page"
              render={(props) => <ForgetPassword {...props} />}
            />
            <Route
              path="/recovery/resetpassword/:token"
              name="Reset Password Page"
              render={(props) => <NewPassword {...props} />}
          />
            <PrivateRoute path="/" component={TheLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(App);
