import { CHANGE_STATE } from "../actions/types";

const initialState = {
  sidebarShow: "responsive",
};

export const changeState = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_STATE:
      return Object.assign({}, state, {
        ...state,
        sidebarShow: action.payload,
      });
    default:
      return state;
  }
};
