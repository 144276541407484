import {
  GET_ALL_AVAILABLE_JOBS,
  JOB_LAUNCH,
  GET_LAUNCHED_JOBS,
  DELETEJOB,
  ACTIVATE_SCHEDULER,
  DEACTIVATE_SCHEDULER,
} from "./types";
import { jobDefinitionService, jobLauncherService } from "../../services";
import { alertActions } from "./alert.actions";
import { modifyJobOfOrganization } from "./organizationsActions";

// ACTION CREATORS
export const getAllAvailableJobs = () => {
  return (dispatch) => {
    jobDefinitionService.getAvailables().then(
      (jobs) => {
        dispatch(getallAvailableJobs(jobs));
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };
};

export function getallAvailableJobs(jobs) {
  return {
    type: GET_ALL_AVAILABLE_JOBS,
    payload: jobs,
  };
}

/**
 *
 */
export const launchJob = (param) => {
  return (dispatch) => {
    jobLauncherService.add(param).then(
      (job) => {
        dispatch(launchjob(job));
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };
};

export function launchjob(jobs) {
  return {
    type: JOB_LAUNCH,
    payload: jobs,
  };
}

/**
 *
 */
export const activateScheduler = (param, organizations) => {
  return (dispatch) => {
    jobLauncherService.activateScheduler(param).then(
      (job) => {
        dispatch(activatescheduler(job));
        organizations.map((organization, organizationIndex) => {
          organization.jobs.map((job, jobIndex) => {
            if (job._id === param.jobdefinition) {
              job.active = !job.active;
              dispatch(
                modifyJobOfOrganization(organizationIndex, jobIndex, job)
              );
              dispatch(getLaunchedJobs());
            }
          });
        });
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };
};

export function activatescheduler(job) {
  return {
    type: ACTIVATE_SCHEDULER,
    payload: job,
  };
}

/**
 *
 */
export const deactivateScheduler = (param, organizations, jobDeleted) => {
  return (dispatch) => {
    jobLauncherService.deactivateScheduler(param).then(
      (job) => {
        dispatch(deactivatescheduler(job));
        if (!jobDeleted)
          organizations.map((organization, organizationIndex) => {
            organization.jobs.map((job, jobIndex) => {
              if (job._id === param.jobdefinition) {
                job.active = !job.active;
                dispatch(
                  modifyJobOfOrganization(organizationIndex, jobIndex, job)
                );
                dispatch(getLaunchedJobs());
              }
            });
          });
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };
};

export function deactivatescheduler(job) {
  return {
    type: DEACTIVATE_SCHEDULER,
    payload: job,
  };
}

/**
 *
 */
export const getLaunchedJobs = () => {
  return (dispatch) => {
    jobLauncherService.getAll().then(
      (launchegJobs) => {
        dispatch(getlaunchedJobs(launchegJobs));
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  };
};

export function getlaunchedJobs(launchegJobs) {
  return {
    type: GET_LAUNCHED_JOBS,
    payload: launchegJobs,
  };
}

export function deleteJob(id) {
  return (dispatch) => {
    jobLauncherService.remove(id).then(
      (job) => {
        dispatch(deletejob(job));
        dispatch(alertActions.success("Le job a été supprimé"));
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function deletejob(data) {
  return {
    type: DELETEJOB,
    payload: data,
  };
}
