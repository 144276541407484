/**
 * import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
    apiKey: "AIzaSyCvoDLE86OkUDfIIvpdxelaDHyh7RqrcKA",
    authDomain: "zto-quable-connector.firebaseapp.com",
    projectId: "zto-quable-connector",
    storageBucket: "zto-quable-connector.appspot.com",
    messagingSenderId: "217534431135",
    appId: "1:217534431135:web:853b84c603d6a3c93807aa"
};

firebase.initializeApp(config);
const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    console.log("request")
    
      Notification.requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

 */

import firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyCvoDLE86OkUDfIIvpdxelaDHyh7RqrcKA",
  authDomain: "zto-quable-connector.firebaseapp.com",
  projectId: "zto-quable-connector",
  storageBucket: "zto-quable-connector.appspot.com",
  messagingSenderId: "217534431135",
  appId: "1:217534431135:web:853b84c603d6a3c93807aa",
});
const messaging = initializedFirebaseApp.messaging();
messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
  "BDK3WupGSYG-x9739YXa3Olef7vqe5oYyrtTpSDZgdpbGBxIrsM9rOn88Onfn6VGIcRwubBjhtMXFvgaQg5IAJc"
);
export { messaging };
