import React from "react";
import PropTypes from "prop-types";
import {
  CButton,
  CCol,
  CModal,
  CModalTitle,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
} from "@coreui/react";
import { connect } from "react-redux";

import {
  addSettingToOrganization,
  modifySettingOfOrganization,
} from "src/redux/actions/organizationsActions";

const SettingsModal = (props) => {
  const [nom, setNom] = React.useState("");
  const [urlPIM, setUrlPIM] = React.useState("");
  const [tokenPIM, setTokenPIM] = React.useState("");
  const [host, setHost] = React.useState("");
  const [login, setLogin] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remotePath, setRemotePath] = React.useState("");

  React.useEffect(() => {
    setNom("");
    setUrlPIM("");
    setTokenPIM("");
    setHost("");
    setLogin("");
    setPassword("");
    setRemotePath("");
    if (props.action === "modify") {
      setNom(
        props.organizations[props.selectedIndex].settings[
          props.selectedSettingIndex
        ].nom.split("- ")[1]
      );
      setUrlPIM(
        props.organizations[props.selectedIndex].settings[
          props.selectedSettingIndex
        ].url_pim
      );
      setTokenPIM(
        props.organizations[props.selectedIndex].settings[
          props.selectedSettingIndex
        ].token_pim
      );
      setHost(
        props.organizations[props.selectedIndex].settings[
          props.selectedSettingIndex
        ].host
      );
      setLogin(
        props.organizations[props.selectedIndex].settings[
          props.selectedSettingIndex
        ].login
      );
      setPassword("");
      setRemotePath(
        props.organizations[props.selectedIndex].settings[
          props.selectedSettingIndex
        ].remote_path
      );
    }
  }, [props.open, props.selectedSettingIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CModal
      show={props.open}
      onClose={() => props.setOpen(!props.open)}
      size="lg">
      <CModalHeader closeButton>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm
          action=""
          method="post"
          encType="multipart/form-data"
          className="form-horizontal">
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Nom
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Paramètrage PIM
          </p>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                URL PIM
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={urlPIM}
                onChange={(e) => setUrlPIM(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                TOKEN PIM
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={tokenPIM}
                onChange={(e) => setTokenPIM(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Paramètrage SFTP
          </p>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Hôte
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={host}
                onChange={(e) => setHost(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Utilisateur
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Mot de passe
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                type="password"
                id="text-input"
                name="text-input"
                placeholder={
                  props.action === "add"
                    ? ""
                    : "laissez vide pour garder le même mot de passe"
                }
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Chemin Distant
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={remotePath}
                onChange={(e) => setRemotePath(e.target.value)}
              />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton
          color="primary"
          disabled={
            nom.length === 0 ||
            urlPIM.length === 0 ||
            tokenPIM.length === 0 ||
            host.length === 0 ||
            login.length === 0 ||
            (props.action === "add" && password.length === 0) ||
            remotePath.length === 0
          }
          onClick={() => {
            props.setOpen(!props.open);
            props.dispatch(
              props.action === "add"
                ? addSettingToOrganization(props.selectedIndex, {
                    nom:
                      props.organizations[props.selectedIndex].nom +
                      " - " +
                      nom,
                    url_pim: urlPIM,
                    token_pim: tokenPIM,
                    host: host,
                    login: login,
                    password: password,
                    remote_path: remotePath,
                    organisationID:
                      props.organizations[props.selectedIndex]._id,
                  })
                : modifySettingOfOrganization(
                    props.selectedIndex,
                    props.selectedSettingIndex,
                    {
                      _id: props.organizations[props.selectedIndex].settings[
                        props.selectedSettingIndex
                      ]._id,
                      ...(nom !==
                        props.organizations[props.selectedIndex].settings[
                          props.selectedSettingIndex
                        ].nom && {
                        nom:
                          props.organizations[props.selectedIndex].nom +
                          " - " +
                          nom,
                      }),
                      ...(urlPIM !==
                        props.organizations[props.selectedIndex].settings[
                          props.selectedSettingIndex
                        ].url_pim && { url_pim: urlPIM }),
                      ...(tokenPIM !==
                        props.organizations[props.selectedIndex].settings[
                          props.selectedSettingIndex
                        ].token_pim && { token_pim: tokenPIM }),
                      ...(host !==
                        props.organizations[props.selectedIndex].settings[
                          props.selectedSettingIndex
                        ].host && { host: host }),
                      ...(login !==
                        props.organizations[props.selectedIndex].settings[
                          props.selectedSettingIndex
                        ].login && { login: login }),
                      ...(password.length > 0 && { password: password }),
                      ...(remotePath !==
                        props.organizations[props.selectedIndex].settings[
                          props.selectedSettingIndex
                        ].remote_path && { remote_path: remotePath }),
                    }
                  )
            );
          }}>
          {props.btnTxt}
        </CButton>{" "}
        <CButton color="secondary" onClick={() => props.setOpen(!props.open)}>
          Annuler
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

function mapStateToProps(state) {
  return {
    organizations: state.organizations.data,
  };
}

SettingsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  action: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectedSettingIndex: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  btnTxt: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SettingsModal);
