export const organisationService = {
  getAll,
  add,
  update,
  remove,
};

function getAll() {
  const requestOptions = {
    method: "get",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/organisations",
    requestOptions
  ).then(handleResponse);
}

function add(organization) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(organization),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/organisations",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} setting
 * @returns
 */
function update(organization) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(organization),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/organisations",
    requestOptions
  ).then(handleResponse);
}

function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    process.env.REACT_APP_API_URL + `/v1/organisations/${id}`,
    requestOptions
  ).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text;
    if (!response.ok) {
      if (data.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        //location.reload(true);
      }

      const error =
        (data &&
          JSON.parse(data).message &&
          JSON.parse(data).message.split("nom: ")[1]) ||
        (data && JSON.parse(data).message) ||
        response.statusText;
      return Promise.reject(error);
    }

    return JSON.parse(data);
  });
}
