import React from "react";
import PropTypes from "prop-types";
import {
  CButton,
  CCol,
  CModal,
  CModalTitle,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
} from "@coreui/react";
import { connect } from "react-redux";

import {
  addUserToOrganization,
  modifyUserOfOrganization,
} from "src/redux/actions/organizationsActions";

const UsersModal = (props) => {
  const [userFirstname, setUserFirstname] = React.useState("");
  const [userLastname, setUserLastname] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");

  const [userProfil, setUserProfil] = React.useState("user");

  React.useEffect(() => {
    setUserFirstname("");
    setUserLastname("");
    setUserEmail("");
    setUserPassword("");
    if (props.action === "modify") {
      setUserFirstname(
        props.organizations[props.selectedIndex].users[props.selectedUserIndex]
          .prenom
      );
      setUserLastname(
        props.organizations[props.selectedIndex].users[props.selectedUserIndex]
          .nom
      );
      setUserEmail(
        props.organizations[props.selectedIndex].users[props.selectedUserIndex]
          .email
      );
      setUserProfil(
        props.organizations[props.selectedIndex].users[props.selectedUserIndex]
          .profil
      );
    }
  }, [props.open, props.selectedUserIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CModal
      show={props.open}
      onClose={() => props.setOpen(!props.open)}
      size="lg">
      <CModalHeader closeButton>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm
          action=""
          method="post"
          encType="multipart/form-data"
          className="form-horizontal">
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Nom
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={userLastname}
                onChange={(e) => setUserLastname(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Prénom
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={userFirstname}
                onChange={(e) => setUserFirstname(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Email
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Mot de passe
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                type="password"
                id="text-input"
                name="text-input"
                placeholder={
                  props.action === "add"
                    ? ""
                    : "Laissez vide pour garder le même mot de passe"
                }
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="select"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Profil
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CSelect
                custom
                name="select"
                id="select"
                onChange={(e) => {
                  setUserProfil(e.target.value);
                }}>
                <option value="admin">Admin</option>
                <option value="user" selected>
                  User
                </option>
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="select"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Organisation
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CSelect disabled custom name="select" id="select">
                {props.organizations.map((organization, index) => {
                  return (
                    <option
                      key={"option" + index}
                      selected={index === props.selectedIndex}
                      value={index}>
                      {organization.nom}
                    </option>
                  );
                })}
              </CSelect>
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={
            userEmail.length === 0 ||
            userLastname.length === 0 ||
            userFirstname.length === 0 ||
            (props.action === "add" && userPassword.length === 0)
          }
          color="primary"
          onClick={() => {
            props.setOpen(!props.open);
            props.dispatch(
              props.action === "add"
                ? addUserToOrganization(props.selectedIndex, {
                    email: userEmail,
                    nom: userLastname,
                    prenom: userFirstname,
                    profil: userProfil,
                    organisationID:
                      props.organizations[props.selectedIndex]._id,
                    password: userPassword,
                  })
                : modifyUserOfOrganization(
                    props.selectedIndex,
                    props.selectedUserIndex,
                    {
                      _id: props.organizations[props.selectedIndex].users[
                        props.selectedUserIndex
                      ]._id,
                      ...(userEmail !==
                        props.organizations[props.selectedIndex].users[
                          props.selectedUserIndex
                        ].email && { email: userEmail }),
                      ...(userLastname !==
                        props.organizations[props.selectedIndex].users[
                          props.selectedUserIndex
                        ].nom && { nom: userLastname }),
                      ...(userFirstname !==
                        props.organizations[props.selectedIndex].users[
                          props.selectedUserIndex
                        ].prenom && { prenom: userFirstname }),
                      ...(userProfil !==
                        props.organizations[props.selectedIndex].users[
                          props.selectedUserIndex
                        ].profil && { profil: userProfil }),
                      ...(userPassword.length > 0 && {
                        password: userPassword,
                      }),
                    }
                  )
            );
          }}>
          {props.btnTxt}
        </CButton>{" "}
        <CButton color="secondary" onClick={() => props.setOpen(!props.open)}>
          Annuler
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

function mapStateToProps(state) {
  return {
    users: state.users.data,
    organizations: state.organizations.data,
  };
}

UsersModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  action: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectedUserIndex: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  btnTxt: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(UsersModal);
