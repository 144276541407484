import React from "react";
import PropTypes from "prop-types";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { CLink } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { connect } from "react-redux";
import {
  removeJobOfOrganization,
  removeSettingOfOrganization,
} from "src/redux/actions/organizationsActions";
import { deactivateScheduler } from "../redux/actions/jobsActions";
import { SettingsModal } from ".";

const SettingsReactGrid = (props) => {
  const [gridApi, setGridApi] = React.useState(null);
  const [gridColumnApi, setGridColumnApi] = React.useState(null);
  const [rowData, setRowData] = React.useState([]);
  const [selectedSettingIndex, setSelectedSettingIndex] = React.useState();
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  const [modalTitle, setModalTitle] = React.useState();
  const [modalBtnTxt, setModalBtnTxt] = React.useState();
  const [action, setAction] = React.useState();

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
  };

  React.useEffect(() => {
    if (gridApi !== null) {
      const handleResize = () => {
        gridApi.sizeColumnsToFit();
      };
      window.addEventListener("resize", handleResize);
    }
  }, [gridApi]);

  React.useEffect(() => {
    if (gridColumnApi !== null) {
      const savedState = gridColumnApi.getColumnState();
      gridColumnApi.applyColumnState({ state: savedState });
    }
  }, [gridColumnApi]);

  React.useEffect(() => {
    if (gridApi !== null && props.entered) {
      gridApi.sizeColumnsToFit();
    }
  }, [props.show, props.entered]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    let rowData = [];
    props.organizations[props.selectedIndex].settings.map((setting) => {
      rowData.push({
        _id: setting._id,
        Nom: setting.nom.split("- ")[1],
        "URL PIM": setting.url_pim,
        "TOKEN PIM": setting.token_pim,
        Hôte: setting.host,
        Utilisateur: setting.login,
        "Chemin Distant": setting.remote_path,
      });
    });

    setRowData(rowData);
  }, [props.organizations]); // eslint-disable-line react-hooks/exhaustive-deps

  const openSettingModifyModal = (index) => {
    setAction("modify");
    setModalTitle("Modifier un profil");
    setModalBtnTxt("Modifier");
    setSelectedSettingIndex(index);
    setSettingsOpen(true);
  };

  const deletesetting = (_id) => {
    confirmAlert({
      title: "Confirmation de suppression ",
      message:
        "Vous allez supprimer ce setting. Cette action ne peut pas être annulée",
      buttons: [
        {
          label: "OUI",
          onClick: () => {
            props.organizations[props.selectedIndex].jobs
              .filter((job) => job.setting === _id)
              .map((job) => {
                const index = props.jobs.launched
                  .map((lj) =>
                    lj.status === "active" ? lj.nom + " active" : lj.nom
                  )
                  .indexOf(job.nom + " active");
                if (
                  index >= 0 &&
                  props.jobs.launched[index].status === "active"
                ) {
                  props.dispatch(
                    deactivateScheduler(
                      props.jobs.launched[index],
                      props.organizations,
                      true
                    )
                  );
                }
                props.dispatch(
                  removeJobOfOrganization(props.selectedIndex, job._id)
                );
              });
            props.dispatch(
              removeSettingOfOrganization(props.selectedIndex, _id)
            );
          },
        },
        {
          label: "NON",
          onClick: () => {},
        },
      ],
    });
  };

  const setSettingsOpenFromChild = (open) => {
    setSettingsOpen(open);
  };

  const modifyProfilButton = (agGridProps) => {
    return (
      <div>
        <CLink
          className="card-header-action"
          onClick={() => openSettingModifyModal(agGridProps.rowIndex)}>
          <CIcon name={"cil-pencil"} />
        </CLink>
        <CLink
          className="card-header-action"
          onClick={() => deletesetting(agGridProps.data._id)}>
          <CIcon name={"cil-x-circle"} />
        </CLink>
      </div>
    );
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
      <AgGridReact
        onGridReady={onGridReady}
        rowData={rowData}
        frameworkComponents={{
          modifyProfilButton: modifyProfilButton,
        }}>
        <AgGridColumn field="Nom" resizable></AgGridColumn>
        <AgGridColumn field="URL PIM" resizable></AgGridColumn>
        <AgGridColumn field="TOKEN PIM" resizable></AgGridColumn>
        <AgGridColumn field="Hôte" resizable></AgGridColumn>
        <AgGridColumn field="Utilisateur" resizable></AgGridColumn>
        <AgGridColumn field="Chemin Distant" resizable></AgGridColumn>
        <AgGridColumn
          field="Actions"
          cellRenderer="modifyProfilButton"
          resizable
        />
      </AgGridReact>
      <SettingsModal
        open={settingsOpen}
        setOpen={setSettingsOpenFromChild}
        action={action}
        title={modalTitle}
        btnTxt={modalBtnTxt}
        selectedIndex={props.selectedIndex}
        selectedSettingIndex={selectedSettingIndex}></SettingsModal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    organizations: state.organizations.data,
    jobs: state.jobs,
  };
}

SettingsReactGrid.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  jobs: PropTypes.object.isRequired,
  entered: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(SettingsReactGrid);
