export const jobDefinitionService = {
  add,
  update,
  remove,
  getAll,
  getAvailables,
};

const loggedUser = JSON.parse(localStorage.getItem("user"));
let organisationFilter = {};
if (loggedUser && loggedUser.profil !== "ADMIN")
  organisationFilter = { organisationID: loggedUser.organisationID._id };
/**
 *
 * @returns
 */

function getAll() {
  const requestOptions = {
    method: "get",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/jobdefinition",
    requestOptions
  ).then(handleResponse);
}

function getAvailables() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(organisationFilter),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/jobdefinition/availables",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} jobDefinition
 * @returns
 */
function add(jobDefinition) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(jobDefinition),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/jobdefinition",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} jobDefinition
 * @returns
 */
function update(jobDefinition) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(jobDefinition),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/jobdefinition",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} jobDefinition
 * @returns
 */
function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    process.env.REACT_APP_API_URL + `/v1/jobdefinition/${id}`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} response
 * @returns
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text;
    if (!response.ok) {
      if (response.status === 401) {
        const error = "Connexion impossible. Vérifier vos identifiants ";
        return Promise.reject(error);
      }

      const error =
        (data &&
          JSON.parse(data).message &&
          JSON.parse(data).message.split("nom: ")[1]) ||
        (data && JSON.parse(data).message) ||
        response.statusText;
      return Promise.reject(error);
    }
    return JSON.parse(data);
  });
}
