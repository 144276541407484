export const jobLauncherService = {
  add,
  getAll,
  remove,
  activateScheduler,
  deactivateScheduler,
};
/**
 *
 * @returns
 */
const loggedUser = JSON.parse(localStorage.getItem("user"));
let organisationFilter = {};
if (loggedUser && loggedUser.profil !== "ADMIN")
  organisationFilter = { organisationID: loggedUser.organisationID._id };

function getAll() {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(organisationFilter),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/joblauncher/avalaibles",
    requestOptions
  ).then(handleResponse);
}

function remove(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    process.env.REACT_APP_API_URL + `/v1/joblauncher/${id}`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} jobDefinition
 * @returns
 */
function add(joblauncher) {
  joblauncher.fcmtoken = localStorage.getItem("msgtoken")
    ? localStorage.getItem("msgtoken")
    : null;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(joblauncher),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/joblauncher",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} jobDefinition
 * @returns
 */
function activateScheduler(joblauncher) {
  joblauncher.fcmtoken = localStorage.getItem("msgtoken")
    ? localStorage.getItem("msgtoken")
    : null;
  joblauncher.scheduled = true;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(joblauncher),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/joblauncher",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} jobDefinition
 * @returns
 */
function deactivateScheduler(joblauncher) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(joblauncher),
  };

  return fetch(
    process.env.REACT_APP_API_URL + "/v1/joblauncher",
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} response
 * @returns
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text;
    if (!response.ok) {
      if (response.status === 401) {
        const error = "Connexion impossible. Vérifier vos identifiants ";
        return Promise.reject(error);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return JSON.parse(data);
  });
}
