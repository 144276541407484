import React from "react";
import PropTypes from "prop-types";
import { isValidCron } from 'cron-validator';
import {
  CButton,
  CCol,
  CModal,
  CModalTitle,
  CModalHeader,
  CModalBody,
  CModalFooter,
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CSelect,
  CInputFile,
  CCard,
  CCardHeader,
  CCardBody,
} from "@coreui/react";
import { connect } from "react-redux";

import {
  addJobToOrganization,
  modifyJobOfOrganization,
} from "src/redux/actions/organizationsActions";

const isCronValid = (expression) => {
  return isValidCron(expression);
};

const JobsModal = (props) => {
  const [nom, setNom] = React.useState("");
  const [setting, setSetting] = React.useState();
  const [jsoncontent, setJSONContent] = React.useState("");
  const [scheduler, setScheduler] = React.useState();
  const [cronExpression, setCronExpression] = React.useState("");


  React.useEffect(() => {
    setNom("");
    setSetting("");
    if (props.action === "modify") {
      const selectedJob = props.organizations[props.selectedIndex].jobs[props.selectedJobIndex];
      setNom(
        props.organizations[props.selectedIndex].jobs[
          props.selectedJobIndex
        ].nom.split("- ")[1]
      );
      setSetting(
        props.organizations[props.selectedIndex].jobs[props.selectedJobIndex]
          .setting
      );
      setJSONContent(
        props.organizations[props.selectedIndex].jobs[props.selectedJobIndex]
          .jsoncontent
      );
      setScheduler(selectedJob.scheduler);
      setCronExpression(selectedJob.cronExpression || "");
    } else if (props.action === "add") {
      if (props.organizations[props.selectedIndex].settings.length > 0) {
        setSetting(props.organizations[props.selectedIndex].settings[0]._id);
      }
    }
  }, [props.open, props.selectedJobIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <CModal
      show={props.open}
      onClose={() => props.setOpen(!props.open)}
      size="lg">
      <CModalHeader closeButton>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CForm
          action=""
          method="post"
          encType="multipart/form-data"
          className="form-horizontal">
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Nom
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="text-input"
                name="text-input"
                placeholder=""
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="select"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Paramètres de connexion
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CSelect
                custom
                name="select"
                id="select"
                onChange={(e) => {
                  setSetting(e.target.value);
                }}>
                <option value="" selected={true}>
                  sélectionner les paramètres de connexion
                </option>
                {props.selectedIndex !== undefined &&
                  props.organizations[props.selectedIndex].settings.map(
                    (setting, index) => {
                      return (
                        <option
                          key={"option" + index}
                          selected={
                            (props.action === "modify" &&
                              setting._id ===
                                props.organizations[props.selectedIndex].jobs[
                                  props.selectedJobIndex
                                ].setting) ||
                            index === 0
                          }
                          value={setting._id}>
                          {setting.nom.split("- ")[1]}
                        </option>
                      );
                    }
                  )}
              </CSelect>
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="text-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                JSON
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInputFile
                id="file-input"
                name="file-input"
                onChange={(e) => {
                  var reader = new FileReader();
                  reader.readAsText(e.target.files[0], "UTF-8");
                  reader.onload = function (evt) {
                    let isJson;
                    try {
                      JSON.parse(evt.target.result);
                      isJson = true;
                    } catch (e) {
                      isJson = false;
                    }
                    isJson
                      ? setJSONContent(JSON.parse(evt.target.result))
                      : setJSONContent("error");
                  };
                  reader.onerror = function () {
                    document.getElementById("fileContents").innerHTML =
                      "error reading file";
                  };
                }}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="select"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Planificateur
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CSelect
                custom
                name="select"
                id="select"
                value={scheduler}
                onChange={(e) => {
                  setScheduler(e.target.value);
                }}>
                <option
                  value={"none"}
                  selected={
                    (props.action === "modify" &&
                      "none" ===
                        props.organizations[props.selectedIndex].jobs[
                          props.selectedJobIndex
                        ].scheduler) ||
                    props.action === "add"
                  }>
                  sélectionner un planificateur
                </option>
                <option
                  value={"every_day"}
                  selected={
                    props.action === "modify" &&
                    "every_day" ===
                      props.organizations[props.selectedIndex].jobs[
                        props.selectedJobIndex
                      ].scheduler
                  }>
                  Chaque jour
                </option>
                <option
                  value={"every_week"}
                  selected={
                    props.action === "modify" &&
                    "every_week" ===
                      props.organizations[props.selectedIndex].jobs[
                        props.selectedJobIndex
                      ].scheduler
                  }>
                  Chaque semaine
                </option>
                <option
                  value={"every_month"}
                  selected={
                    props.action === "modify" &&
                    "every_month" ===
                      props.organizations[props.selectedIndex].jobs[
                        props.selectedJobIndex
                      ].scheduler
                  }>
                  Chaque mois
                </option>
                <option
                  value={"custom"}
                  selected={
                    props.action === "modify" &&
                    "custom" ===
                      props.organizations[props.selectedIndex].jobs[
                        props.selectedJobIndex
                      ].scheduler
                  }>
                  Custom
                </option>
              </CSelect>
            </CCol>
          </CFormGroup>
          {scheduler === "custom" && (
          <CFormGroup row>
            <CCol md="3">
              <CLabel
                htmlFor="cron-input"
                class="col-sm-2 col-form-label"
                style={{ whiteSpace: "nowrap" }}>
                Cron Expression
              </CLabel>
            </CCol>
            <CCol xs="12" md="9">
              <CInput
                id="cron-input"
                name="cron-input"
                placeholder="0 8-12,14-16 * * *"
                value={cronExpression || ""}
                onChange={(e) => setCronExpression(e.target.value)}
              />
            </CCol>
          </CFormGroup>
          )}
        </CForm>
        <CCard color="info" className="text-white">
          <CCardHeader>Aide</CCardHeader>
          <CCardBody>
            Allez à la page Export pour <b>Lancer les jobs</b> et{" "}
            <b>Activer ou Désactiver les jobs avec un planificateur.</b> Les
            jobs avec un planificateur se lancent toujours à 8H.
            <br />
            Les jobs planifiés pour chaque semaine se lancent le jour 1, 7, 14,
            21 et 28 de chaque mois.
            <br />
            Les jobs planifiés pour chaque mois se lancent le premier jour du
            mois.
          </CCardBody>
        </CCard>
        {props.action === "modify" && (
          <CCard color="warning" className="test-white">
            <CCardHeader>Attention</CCardHeader>
            <CCardBody>
              Modifier un job entraine son arret s'il est déjà planifié.
              <br/>
              Pensez à le relancer.
            </CCardBody>
          </CCard>
        )}
      </CModalBody>

      <CModalFooter>
        <CButton
          color="primary"
          disabled={
            nom.length === 0 ||
            jsoncontent === undefined ||
            setting.length === 0 ||
            (scheduler === "custom" && !isCronValid(cronExpression))
          }
          onClick={() => {
            props.setOpen(!props.open);
            props.dispatch(
              props.action === "add"
                ? addJobToOrganization(props.selectedIndex, {
                    nom:
                      props.organizations[props.selectedIndex].nom +
                      " - " +
                      nom,
                    setting: setting,
                    jsoncontent: jsoncontent,
                    ...(scheduler !== "none" && { scheduler: scheduler }),
                    organisationID:
                      props.organizations[props.selectedIndex]._id,
                    ...(scheduler !== "none" && { active: false }),
                    ...(scheduler === "custom" && { cronExpression: cronExpression })
                  })
                : modifyJobOfOrganization(
                    props.selectedIndex,
                    props.selectedJobIndex,
                    {
                      _id: props.organizations[props.selectedIndex].jobs[
                        props.selectedJobIndex
                      ]._id,
                      nom:
                        props.organizations[props.selectedIndex].nom +
                        " - " +
                        nom,
                      setting: setting,
                      jsoncontent: jsoncontent,
                      ...(scheduler !== "none" && { scheduler: scheduler }),
                      organisationID:
                        props.organizations[props.selectedIndex]._id,
                      ...(scheduler !== "none" && { active: false }),
                      ...(scheduler === "custom" && { cronExpression: cronExpression })
                    }
                  )
            );
          }}>
          {props.btnTxt}
        </CButton>{" "}
        <CButton color="secondary" onClick={() => props.setOpen(!props.open)}>
          Annuler
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

function mapStateToProps(state) {
  return {
    organizations: state.organizations.data,
  };
}

JobsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organizations: PropTypes.array.isRequired,
  action: PropTypes.string.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectedJobIndex: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  btnTxt: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(JobsModal);
