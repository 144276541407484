import { LOGIN_USER, LOGOUT_USER } from "./types";
import { userService } from "../../services";
import { alertActions } from "./alert.actions";

export function modifyUserById(data) {
  return (dispatch) => {
    userService.update(data).then(
      () => {
        //dispatch(modifyuserById(user))
        dispatch(
          alertActions.success(
            "Votre mot de passe a été modifiée"
          )
        );
      },
      (error) => {
        dispatch(alertActions.error(error));
      }
    );
  }
}
/*
export function modifyuserById(data) {
  console.log('password')
  return {
    type: MODIFY_USER_PASSWORD_BY_ID,
    payload: {
      data: data,
    },
  };
}
*/



export function askForResetToken(email) {
  userService.askForResetToken(email)
    .then()
    .catch((error) => {
      console.log(error)
    })
}

export function resetPassword(token, newPassword) {
  return userService.resetPassword(token, newPassword)
    .then((result) => {
      return result
    })
    .catch((error) => {
      console.log(error)
    })
} 

export async function getUserResetToken(token) {
  return userService.getOnePasswordResetToken(token)
    .then((token) => {
        return token
    })
    .catch((error) => {
      console.log(error)
    })
}

export function loginUser(username, password) {
  return (dispatch) => {
    userService.login(username, password).then(
      (user) => {
        dispatch(loginuser(user.user));
        dispatch(
          alertActions.success(
            "Bienvenue " + user.user.nom + " " + user.user.prenom
          )
        );
        window.location.reload();
      },
      (error) => {
        //dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

export function loginuser(data) {
  return {
    type: LOGIN_USER,
    payload: data,
  };
}

export function logOut() {
  return (dispatch) => {
    userService.logout();
    window.location.reload();
    dispatch(logout());
  };
}

export function logout() {
  return {
    type: LOGOUT_USER,
  };
}
