import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logOut } from "../../redux/actions/usersActions";
import { Redirect } from "react-router-dom";
import { CIcon } from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";

const Logout = (props) => {
  return (
    <div>
      <Redirect to="/" />
      <div
        className="logOutLink"
        onClick={() => {
          props.dispatch(logOut());
        }}>
        <b>Déconnexion&nbsp;&nbsp;&nbsp;</b>
        <CIcon content={freeSet.cilAccountLogout} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { isLogged: state.users.isLogged };
}

Logout.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Logout);
