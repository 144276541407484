import { LOGIN_USER, LOGOUT_USER } from "../actions/types";

const initialState = {
  loggedUser: {},
  isLogged: localStorage.getItem("user") ? true : false,
};

export function usersReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return Object.assign({}, state, {
        ...state,
        loggedUser: action.payload,
        isLogged: true,
      });
    case LOGOUT_USER:
      return Object.assign({}, state, {
        ...state,
        loggedUser: {},
        isLogged: false,
      });
    default:
      return state;
  }
}
